import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {IData} from "../interfaces/statistics";
import {StatisticsServices} from "../services/statistics";

const data = (token: string, params: {currency: string}) => {
  return (dispatch: Dispatch): Promise<Array<IData>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return StatisticsServices.data(token, params)
      .then(
        data => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(data)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const StatisticsActions = {
  data
}