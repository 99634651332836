import React, {ReactElement, useEffect, useState} from "react"
import {
  Box,
  IconButton,
  Table as MUITable,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow as MUITableRow,
  styled
} from "@mui/material";
import {IColumn} from "../interfaces/Table/column";
import {Filter} from "./Table/Filter";
import {IFilter} from "../interfaces/Table/filter";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";

const TableCell = styled(MUITableCell)(({theme}) => ({
  padding: theme.spacing(1),
  height: "60px",
  '&.MuiTableCell-head': {
    backgroundColor: 'rgb(241, 243, 244)',
    color: 'rgb(99, 115, 129)',
    borderBottomColor: 'rgb(241, 243, 244)',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    fontWeight: 400
  },
  '&.MuiTableCell-root': {
    borderBottomColor: 'rgb(241, 243, 244)'
  }
}));

const TableRow = styled(MUITableRow)(({theme}) => ({
  '&.MuiTableRow-hover': {
    '&:hover': {
      backgroundColor: 'rgba(145, 158, 171, 0.08)'
    }
  }
}));

interface Props {
  collapse?: string,
  columns: Array<IColumn>,
  rows: Array<object>,
  meta: {
    total: number
  },
  params: {
    page: number,
    limit: number,
    search: string | null,
    filter: IFilter
  }
  callbackChange: (page: number, size: number, filter: IFilter) => void
  onClick?: (id: number) => void
}

export function Table(props: Props): ReactElement {
  const {collapse, columns, rows, meta, params, callbackChange, onClick} = props
  const [filter, setFilter] = React.useState<IFilter>(params.filter);
  const [open, setOpen] = React.useState<number | null>();
  const [page, setPage] = React.useState(params.page - 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(params.limit);
  const [init, setInit] = useState<boolean>(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    setInit(true)
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (init) {
      callbackChange(page + 1, rowsPerPage, filter)
    }
    //eslint-disable-next-line
  }, [page, rowsPerPage, filter])

  const hasCollapse = (item: object) => {
    const rows: Array<object> = item[collapse as keyof object]

    if (collapse && rows) {
      return !!rows.length
    }

    return false
  }

  const getCollapse = (item: object, index: number) => {
    const rows: Array<object> = item[collapse as keyof object]

    if (collapse && (open === index) && rows.length) {
      return rows.map((row, index) => {
        return (
          <TableRow
            hover
            key={index}
            sx={{
              cursor: onClick ? "pointer" : "default",
              backgroundColor: "rgba(139,76,247, 0.02)"
            }}
            onClick={() => {
              if (onClick && row.hasOwnProperty('id')) {
                onClick(row['id' as keyof object])
              }
            }}
          >
            <TableCell/>
            {columns.map((column) => {
              const value = row[column.key as keyof object]

              return (
                <TableCell key={column.key} align={column.align}>
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })
    }

    return null
  }

  return (
    <Box
      sx={{
        mt: 'auto',
      }}
    >
      <TableContainer>
        <MUITable stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell/>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                >
                  {column.filter ? <Filter column={column} filter={filter} setFilter={setFilter}/> : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length ? rows.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow
                    hover
                    sx={{
                      cursor: onClick ? "pointer" : "default"
                    }}
                    onClick={() => {
                      if (onClick && row.hasOwnProperty('id')) {
                        onClick(row['id' as keyof object])
                      }
                    }}
                  >
                    {hasCollapse(row) ? (
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation()
                            setOpen((open === index) ? null : index)
                          }}
                        >
                          {(open === index) ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                        </IconButton>
                      </TableCell>
                    ) : <TableCell/>}
                    {columns.map((column) => {
                      const value = row[column.key as keyof object]
                      return (
                        <TableCell key={column.key} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {getCollapse(row, index)}
                </React.Fragment>
              );
            }) : (
              <TableRow hover>
                <TableCell colSpan={columns.length + 1} align="center">
                  Нет данных
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </TableContainer>
      <Box
        sx={{
          padding: '8px 24px',
          mt: 'auto'
        }}
      >
        <TablePagination
          sx={{
            '& .MuiTablePagination-select': {
              '&:focus': {
                borderRadius: '6px'
              }
            }
          }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={meta.total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={null}
          labelDisplayedRows={({from, to, count}) => {
            return `${from} – ${to} из ${count !== -1 ? count : `больше, чем ${to}`}`;
          }}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  )
}