import {createTheme} from "@mui/material/styles";
import { ruRU as dataGridRuRU } from '@mui/x-data-grid';
import { ruRU as coreRuRU } from '@mui/material/locale';
import { ruRU } from '@mui/x-date-pickers/locales';

const base = createTheme({
  typography: {
    fontFamily: 'Figtree, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
  },
  palette: {
    primary: {
      light: 'rgb(0,202,134)',
      main: 'rgb(0, 167, 111)',
      dark: 'rgb(0,115,77)',
    }
  },
})

export const theme = createTheme(base,
  {
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
            margin: 0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            scrollbar-gutter: stable both-edges;
            overflow: auto;
          }
          ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background: transparent
          }
          ::-webkit-scrollbar-track {
            background: transparent
          }
          ::-webkit-scrollbar-thumb {
            background-color: rgba(0, 167, 111, 0.5);
          }
          ::-webkit-scrollbar-corner {
            overflow: hidden;
          }
          ::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 167, 111, 0.5);
          }
        `
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: '6px'
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            padding: '8px 8px 4px 8px',
            borderRadius: '6px',
            marginTop: '8px',
            boxShadow: 'rgba(145, 158, 171, 0.2) 0px 5px 5px -3px, rgba(145, 158, 171, 0.14) 0px 8px 10px 1px, rgba(145, 158, 171, 0.12) 0px 3px 14px 2px',
            '& .MuiAutocomplete-listbox': {
              '& .MuiAutocomplete-option': {
                marginBottom: '4px',
                borderRadius: '6px',
              },
              padding: 0,
            }
          }
        }
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            padding: '8px 8px 4px 8px',
            borderRadius: '6px',
            marginTop: '8px',
            boxShadow: 'rgba(145, 158, 171, 0.2) 0px 5px 5px -3px, rgba(145, 158, 171, 0.14) 0px 8px 10px 1px, rgba(145, 158, 171, 0.12) 0px 3px 14px 2px',
            '& .MuiList-root': {
              '& .MuiButtonBase-root': {
                marginBottom: '4px',
                borderRadius: '6px',
              },
              padding: 0
            }
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            [base.breakpoints.up('sm')]: {
              borderRadius: '16px',
            },
          }
        }
      }
    }
  },
  ruRU,
  dataGridRuRU,
  coreRuRU
);