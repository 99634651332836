import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {
  Grid,
  Stack,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useAppSelector} from "../App/hooks/store";
import {IColumn} from "../App/interfaces/Table/column";
import {IFilter as ISortFilter} from "../App/interfaces/Table/filter";
import {Table} from "../App/components/Table";
import {Data, IData} from "../App/interfaces/data";
import {IInvoice} from "./interfaces/invoice";
import {IFilter} from "../App/interfaces/filter";
import {InvoiceActions} from "./actions/invoice";
import {useDebouncedCallback} from "use-debounce";
import {status} from "./constants/status";
import {Actions} from "./components/Buttons/Actions";
import {Filter} from "./components/Filter";
import {TextField} from "../App/components/Input/TextField";
import {Title} from "../App/components/Typography/Title";
import {Content} from "../App/components/Papper/Content";
import {Transaction} from "./components/Buttons/Number";

const columns: Array<IColumn> = [
  {
    id: 1,
    key: 'number',
    label: 'Номер заказа',
    filter: {
      order: true
    }
  },
  {
    id: 2,
    key: 'email',
    label: 'Эл. почта / IP'
  },
  {
    id: 3,
    key: 'gateway',
    label: 'Платежный метод',
    filter: {
      type: 'autocomplete',
      fields: [
        {type: 'method'},
        {type: 'merchant'},
      ]
    }
  },
  {
    id: 4,
    key: 'status',
    label: 'Статус',
    filter: {
      type: 'options',
      options: Object.entries(status).map(([, option]) => ({
        id: option.key,
        name: option.name
      }))
    }
  },
  {
    id: 5,
    key: 'amount',
    label: 'Сумма',
    filter: {
      type: 'interval'
    }
  },
  {
    id: 6,
    key: 'created',
    label: 'Дата и время создания',
    filter: {
      order: true
    }
  },
  {
    id: 7,
    key: 'actions',
    align: 'right'
  },
]

export function Invoices(): ReactElement | null {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const {seller} = useAppSelector(state => state.seller)
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [params, setParams] = useState<{
    page: number,
    limit: number,
    search: string | null,
    filter: ISortFilter
  }>({page: 1, limit: 10, search: null, filter: {order: {name: 'created', direction: 'desc'}}})
  const [items, setItems] = useState<IData<IInvoice>>(Data)

  const debounced = useDebouncedCallback(
    (value: string) => {
      setParams({
        ...params,
        page: 1,
        search: value
      })
    },
    900
  );

  useEffect(() => {
    if (seller) {
      dispatch(InvoiceActions.items(seller.token, {
        page: params.page,
        limit: params.limit,
        order: params.filter.order.name,
        direction: params.filter.order.direction,
        ...(params.search ? {search: params.search} : {}),
        ...(params.filter.filters ? Object.entries(params.filter.filters).reduce((obj, [name, values]) => {
          return {
            ...obj,
            ...(values.length ? {[name]: values.join(',')} : {})
          }
        }, {}) : {}),
        ...(params.filter.values ? Object.entries(params.filter.values).reduce((obj, [name, values]) => {
          return {
            ...obj,
            ...((values.hasOwnProperty('method') && values.method.length) ? {[`${name}[method]`]: values.method.map(value => value.key).join(',')} : {}),
            ...((values.hasOwnProperty('merchant') && values.merchant.length) ? {[`${name}[merchant]`]: values.merchant.map(value => value.key).join(',')} : {}),
          }
        }, {}) : {}),
        ...(params.filter.intervals ? Object.entries(params.filter.intervals).reduce((obj, [name, interval]) => {
          return {
            ...obj,
            ...((interval.start !== null) ? {[`${name}[start]`]: interval.start} : {}),
            ...((interval.end !== null) ? {[`${name}[end]`]: interval.end} : {})
          }
        }, {}) : {})
      } as IFilter)).then((positions: IData<IInvoice>) => {
        setItems(positions)
      })
    }
  }, [dispatch, seller, params]);

  return (
    <Stack spacing={2}>
      <Grid item>
        <Title>
          Платежи
        </Title>
      </Grid>
      <Grid item>
        <Grid container direction={mobile ? "column" : "row" as 'column' | "row"} spacing={2}>
          <Grid item xs={12}>
            <Content>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{padding: '24px'}}>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    placeholder="Поиск"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      debounced(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item>
                  <Filter
                    filter={params.filter}
                    setFilter={(filter) => {
                      setParams({
                        ...params,
                        filter: filter
                      })
                    }}
                  />
                </Grid>
              </Grid>
              <Table
                columns={columns}
                rows={items.data.map(item => ({
                  number: <Transaction invoice={item} />,
                  email: <Stack>
                    <Grid item>{item.email}</Grid>
                    <Grid item>{item.ip}</Grid>
                  </Stack>,
                  gateway: <Stack>
                    <Grid item>{item.gateway.method.name}</Grid>
                    <Grid item>{item.merchant.name}</Grid>
                  </Stack>,
                  status: <Stack>
                    <Grid item>{status[item.status].name}</Grid>
                    <Grid item>{item.updated ? new Date(item.updated).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}) : null}</Grid>
                  </Stack>,
                  amount: Number(item.amount).toLocaleString('ru-RU', {style: 'currency', currency: item.currency}),
                  created: item.created ? new Date(item.created).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}) : null,
                  actions: <Actions
                    invoice={item}
                    onChange={(invoice) => {
                      setItems({
                        ...items,
                        data: items.data.map(item => (item.token === invoice.token) ? invoice : item)
                      })
                    }}
                  />
                }))}
                params={params}
                meta={items.meta}
                callbackChange={(page, limit, filter) => {
                  setParams({
                    page: page,
                    limit: limit,
                    search: params.search,
                    filter: {
                      ...params.filter,
                      ...filter,
                      intervals: {
                        ...params.filter.intervals,
                        ...filter.intervals
                      }
                    }
                  })
                }}
              />
            </Content>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}