const currency: { [key: string]: { key: string, name: string } } = {
  RUB: {
    key: 'RUB',
    name: 'Рубль'
  },
  USD: {
    key: 'USD',
    name: 'Доллар США'
  },
  EUR: {
    key: 'EUR',
    name: 'Евро'
  },
}

export {currency}