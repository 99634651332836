import React from "react";
import ReactDOM from "react-dom/client";
import {ThemeProvider} from "@mui/material/styles";
import {Provider} from "react-redux";
import {theme} from "./App/styles/theme";
import {store} from "./App/reducers/store";
import {Routes} from "./App/routes";
import {Alerts} from "./App/components/Alerts";
import {Loading} from "./App/components/Loading";
import {Action} from "./App/components/Action";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {CssBaseline} from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline/>
        <Loading/>
        <Action />
        <Alerts/>
        <Routes/>
      </Provider>
    </ThemeProvider>
  </LocalizationProvider>
);