import React, {ReactElement} from "react";
import {Application as ApplicationLayout} from "./components/Layout/Application";
import {useAppDispatch} from "./hooks/store";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import {Authorize} from "../Auth/Authorize";
import {Logout} from "../Auth/Logout";
import {Login} from "../Auth/Login";
import {Guest} from "../Auth/Guest";
import {Status} from "./components/System/Status";
import {SellerActions} from "../Seller/actions/seller";
import {Settings} from "../Seller/Settings";
import {Invoices} from "../Payment/Invoices";
import {Merchants} from "../Seller/Merchants";
import {Gateways} from "../Seller/Gateways";
import {Statistics} from "../Seller/Statistics";

export function Routes(): ReactElement {
  const dispatch = useAppDispatch();

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Authorize><ApplicationLayout/></Authorize>,
      children: [
        {
          id: "seller",
          loader: async (props) => {
            const params = props.params as { token: string }
            return dispatch(SellerActions.seller(params.token))
          },
          path: "/seller/:token",
          errorElement: <React.Fragment/>,
          children: [
            {
              path: "",
              element: <Navigate to="invoices" replace/>,
            },
            {
              path: "/seller/:token/settings",
              element: <Settings/>
            },
            {
              path: "/seller/:token/statistics",
              element: <Statistics/>
            },
            {
              path: "/seller/:token/invoices",
              element: <Invoices/>
            },
            {
              path: "/seller/:token/merchants",
              element: <Merchants/>
            },
            {
              path: "/seller/:token/gateways",
              element: <Gateways/>
            },
          ]
        },
        {
          path: "/logout",
          element: <Logout/>
        },
      ],
    },
    {
      path: "/login",
      element: <Guest><Login/></Guest>
    },
    {
      path: '*',
      element: <Status code={404} message={'Данные не найдены'}/>
    }
  ]);

  return <RouterProvider router={router}/>
}