import {HttpService} from "../../App/services/http";

const data = (token: string, params: {currency: string}) => {
  return HttpService.get(`/seller/${token}/statistics`, params)
    .then(response => {
      const {data} = response
      return data
    })
}

export const StatisticsServices = {
  data
}